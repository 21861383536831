(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name forgotPassword.controller:ForgotPasswordCtrl
   *
   * @description
   *
   */
  angular
    .module('forgotPassword')
    .controller('ForgotPasswordCtrl', ForgotPasswordCtrl);

  function ForgotPasswordCtrl($location, RCRUsers, Events, Alerts) {
    var vm = this;

	if (RCRUsers.isAuthenticated()) {
		$location.path('/home');
	}

	vm.sendPassword = function () {
    console.log(vm.email);
    RCRUsers.sendReminder({"email":vm.email}, function () {
		Events.create({
			"eventType":"forgot password", 
			"eventData":vm.email
		});
		Alerts.addAlert('ok','New password sent to your email');
    });
  }

  }
}());
